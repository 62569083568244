import { format, addMinutes } from 'date-fns'

function formatCurrency (value) {
  if (!value && value !== 0) return null 
  return ((value * 100) / 100).toFixed(2) 
}

function formatDate (date) {
  if (!date) return
  date = new Date(date)
  date = addMinutes(date, date.getTimezoneOffset())
  return format(date, CONSTANTS.date_format)
}

function formatTimestamp (date) {
  if (!date) return
  date = new Date(date)
  const dateWithOffset = new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
  return format(dateWithOffset, CONSTANTS.timestamp)
}

function objectStringsToFloat (updateObject) {
  Object.keys(updateObject).forEach(key => {
    if (!['id', 'name', 'dt', 'promo_number'].some(v => key.includes(v))) {
      if (!isNaN(parseFloat(updateObject[key]))) {
        updateObject[key] = parseFloat(updateObject[key]).toFixed(2)
        updateObject[key] = parseFloat(updateObject[key])
      }
    }
  })
}

function removeSpecialCharacter (specialCharString) {
  const reg_pattern = /[~`!#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g
  return specialCharString.replace(reg_pattern, '')
}

function nullifyEmptyStrings (obj) {
  for (const key of Object.keys(obj)) {
    if (obj[key] === "") obj[key] = null
  }
  return obj
}

const CONSTANTS = {
  timestamp: 'M/d/yyyy - h:mm:ss a',
  date_format: 'M/d/yyyy',
  formatCurrency: formatCurrency,
  formatDate: formatDate,
  formatTimestamp: formatTimestamp,
  objectStringsToFloat: objectStringsToFloat,
  removeSpecialChar: removeSpecialCharacter,
  nullifyEmptyStrings
}

export default { CONSTANTS }
