import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class ProductEvent {
  constructor () {
    const base = axios.create({
      // using base items api
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })
    this.productEvent = base
  }

  async getList (offset = 0, limit = 50, params = {}) {
    await AuthToken.setAuth(this.productEvent)
    return this.productEvent({
      url: `/product_events`,
      method: 'GET',
      params: { offset, limit, ...params }
    })
  }

  async get (id) {
    await AuthToken.setAuth(this.productEvent)
    return this.productEvent({
      url: `/product_event/${id}`,
      method: 'GET'
    })
  }

  async create (payload) {
    await AuthToken.setAuth(this.productEvent)
    return this.productEvent({
      url: `/product_event`,
      method: 'POST',
      data: payload
    })
  }

  async update (id, payload) {
    await AuthToken.setAuth(this.productEvent)
    return this.productEvent({
      url: `/product_event/${id}`,
      method: 'PUT',
      data: payload
    })
  }

  async delete (id) {
    await AuthToken.setAuth(this.productEvent)
    return this.productEvent({
      url: `/product_event/${id}`,
      method: 'DELETE'
    })
  }

  async exportEventOrders (id) {
    await AuthToken.setAuth(this.productEvent)
    return this.productEvent({
      url: `/product_event/export/${id}`,
      method: 'POST',
    })
  }
}

export default new ProductEvent()