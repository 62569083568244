var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "offset-y": "",
        "nudge-bottom": 5,
        "close-on-content-click": false,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass: "ml-auto text-capitalize",
                    attrs: { rounded: "", text: "", color: "main" },
                  },
                  on
                ),
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-filter-outline"),
                  ]),
                  _vm._v(" Filters "),
                  _c("v-badge", {
                    staticClass: "ml-2 mb-2",
                    attrs: {
                      color: "accent",
                      content: _vm.filterCount,
                      value: _vm.filterCount > 0,
                    },
                  }),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.filterMenu,
        callback: function ($$v) {
          _vm.filterMenu = $$v
        },
        expression: "filterMenu",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "background", attrs: { width: "350" } },
        [
          _c(
            "v-card-text",
            [
              _vm.productGroups.length > 1
                ? _c(
                    "v-col",
                    {
                      staticClass: "px-0",
                      staticStyle: { "max-width": "430px" },
                    },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          items: _vm.productGroups,
                          "item-text": "name",
                          "item-value": "id",
                          "hide-details": "",
                          label: "Event Product Group",
                          "background-color": "white",
                          "prepend-inner-icon": "mdi-filter-outline",
                          clearable: "",
                        },
                        on: { change: _vm.handleChangedGroup },
                        model: {
                          value: _vm.filters["product_group_id"],
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "product_group_id", $$v)
                          },
                          expression: "filters['product_group_id']",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.searchFields, function (field, idx) {
                return _c(
                  "v-row",
                  { key: idx, attrs: { dense: "", justify: "center" } },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "ma-0 pa-0",
                        attrs: {
                          cols:
                            field.type === "boolean" ||
                            field.key === "active_orders"
                              ? 8
                              : null,
                        },
                      },
                      [
                        field.type === "boolean"
                          ? _c("v-switch", {
                              staticClass: "mt-0",
                              attrs: { inset: "", label: field.name },
                              model: {
                                value: _vm.filters[`${field.key}`],
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, `${field.key}`, $$v)
                                },
                                expression: "filters[`${field.key}`]",
                              },
                            })
                          : _c("v-text-field", {
                              staticStyle: { padding: "5px" },
                              attrs: {
                                label: field.name,
                                outlined: "",
                                "hide-details": "auto",
                                hint: "Press enter to search",
                                clearable: "",
                                loading:
                                  Boolean(_vm.filters[field.key]) &&
                                  _vm.loading,
                                color: "primary",
                                dense: "",
                                "prepend-inner-icon": "mdi-magnify",
                                "background-color": "white",
                              },
                              on: {
                                "click:clear": function ($event) {
                                  return _vm.clearFilter(field.key)
                                },
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.updateSearchFilters.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                              model: {
                                value: _vm.filters[`${field.key}`],
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, `${field.key}`, $$v)
                                },
                                expression: "filters[`${field.key}`]",
                              },
                            }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _vm.openStockSelected
                    ? _c(
                        "v-col",
                        {
                          staticClass: "ma-0 pa-0",
                          staticStyle: { "max-width": "430px" },
                        },
                        [
                          _c("v-autocomplete", {
                            staticStyle: { padding: "5px" },
                            attrs: {
                              outlined: "",
                              clearable: "",
                              dense: "",
                              "hide-details": "",
                              items: _vm.productCategories,
                              "background-color": "white",
                              label: "Product Group",
                              color: "primary",
                              "menu-props": { maxHeight: 215 },
                              "prepend-inner-icon": "mdi-filter-outline",
                            },
                            on: { change: _vm.handleChangedGroup },
                            model: {
                              value: _vm.filters[`category`],
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, `category`, $$v)
                              },
                              expression: "filters[`category`]",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _vm.openStockSelected
                    ? _c(
                        "v-col",
                        {
                          staticClass: "ma-0 pa-0",
                          staticStyle: { "max-width": "430px" },
                        },
                        [
                          _c("v-autocomplete", {
                            staticStyle: { padding: "5px" },
                            attrs: {
                              outlined: "",
                              clearable: "",
                              dense: "",
                              "hide-details": "",
                              items: _vm.filteredSubcategories,
                              "background-color": "white",
                              label: "Product Category",
                              color: "primary",
                              "menu-props": { maxHeight: 215 },
                              "prepend-inner-icon": "mdi-filter-outline",
                            },
                            on: { change: _vm.handleChangedGroup },
                            model: {
                              value: _vm.filters[`subcategory`],
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, `subcategory`, $$v)
                              },
                              expression: "filters[`subcategory`]",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }