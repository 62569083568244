import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class Party {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_CUSTOMER_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })
    this.party = base
  }

  async searchParties (data = {}, offset = 0, limit = 250) {
    await AuthToken.setAuth(this.party, {
      audience: process.env.VUE_APP_CUSTOMER_API_AUDIENCE
    })
    return this.party({
      url: 'v1/party/search_parties',
      method: 'POST',
      data,
      params: { offset, limit }
    })
  }

  async getUserProfile (email, include_stores = true) {
    await AuthToken.setAuth(this.party, {
        audience: process.env.VUE_APP_CUSTOMER_API_AUDIENCE
      })
    return this.party({
      url: 'v1/user_profile',
      method: 'GET',
      params: { email, include_stores }
    })
  }

  async massEmail (data) {
    await AuthToken.setAuth(this.party, {
      audience: process.env.VUE_APP_CUSTOMER_API_AUDIENCE
    })
    return this.party({
      url: 'v1/mass_email',
      method: 'POST',
      data,
    })
  }
}

export default new Party()
